<template>
  <div class="about-us">
    <main
      class="relative min-h-screen flex flex-col justify-center overflow-hidden"
    >
      <div class="w-full mx-auto px-4 md:px-6 py-8 my-8">
        <div class="w-full max-w-4xl mx-auto mb-4">
          <h1 class="font-black text-main-purple-500 text-6xl text-center my-4">
            LGPD
          </h1>
          <h3
            class="font-regular text-main-pink-500 text-2xl text-center leading-6"
          >
          A Lei nº 13.709/18 – Lei Geral de Proteção de Dados (LGPD), em vigor desde 18 de setembro de 2020, inspirada na legislação europeia, General Data Protection Regulation (GDPR), foi criada para instituir regras e diretrizes gerais sobre o tratamento de dados pessoais no Brasil.
          </h3>
        </div>
        <div class="flex flex-col justify-center">
          <div class="w-full max-w-4xl mx-auto">
            <!-- Vertical Timeline #1 -->
            <div class="-my-6">
              <faq-accordion faq-question="O que é a LGPD?">
                <p>A Lei nº 13.709/18 – Lei Geral de Proteção de Dados (LGPD), em vigor desde 18 de setembro de 2020, inspirada na legislação europeia, General Data Protection Regulation (GDPR), foi criada para instituir regras e diretrizes gerais sobre o tratamento de dados pessoais no Brasil.</p>
              </faq-accordion>
              
              <faq-accordion faq-question="Como posso obter informações relativas aos dados pessoais coletados pela Nova?">
                <p>Em respeito a você, titular dos dados e em conformidade com a legislação, disponibilizamos um canal onde você poderá exercer seus direitos e buscar informações relativas aos dados coletados.</p>
              </faq-accordion>

              <faq-accordion faq-question="O que são dados pessoais e como proteger?">
                <p>Dados pessoais são aqueles que podem identificar ou tornar identificável uma pessoa natural, como por exemplo, nome, RG, CPF, endereço, cookies, geolocalização, preferências culturais, gostos e interesses.</p>
                <p class="mt-3">Proteção dos dados, envolve um conjunto de práticas e, assim, possibilitar às pessoas decidir como seus dados podem ser utilizados e informá-las sobre as consequências dessa decisão. A proteção de dados é, portanto, uma grande preocupação da Nova, pois compreendemos seu impacto direto nas pessoas e na sociedade.</p>
              </faq-accordion>

              <faq-accordion faq-question="O que são dados pessoais sensíveis?">
                <p>Todo o dado é pessoal, mas nem todos são sensíveis. Os dados pessoais sensíveis, merecem uma atenção especial, e devem ser tratados em hipóteses específicas. São eles: dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político; dados referentes à saúde ou à vida sexual, dados genéticos ou biométricos.</p>
              </faq-accordion>

              <faq-accordion faq-question="O que é tratamento de dados pessoais?">
                <p>É qualquer operação ou o conjunto de operações realizadas com dados pessoais ou com um conjunto de dados pessoais, tais como a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</p>
              </faq-accordion>

              <faq-accordion faq-question="Quem é o titular de dados pessoais?">
                <p>Somos todos nós! Pessoas naturais a quem os dados dizem respeito.</p>
              </faq-accordion>

              <faq-accordion faq-question="Quem fiscaliza a Lei?">
                <p>A Lei Geral de Proteção de Dados criou a ANPD (Autoridade Nacional de Proteção de Dados), órgão da Administração Pública Federal, responsável por aplicar e fiscalizar o cumprimento da lei, além de zelar pela proteção dos dados pessoais.</p>
              </faq-accordion>

              <faq-accordion faq-question="A Nova se classifica como Operadora ou Controladora de dados pessoais?">
                <p>A Nova pode se classificar tanto como Operadora, quanto como Controladora de dados pessoais, a depender do contexto. Por exemplo, a Nova pode ser Controladora quando trata dados pessoais dos seus colaboradores e clientes (dados necessários para firmar contratos e processar pagamentos). Por outro lado, pode ser Operadora para fornecer produtos e serviços, sendo que, para isso, precisa tratar dados referentes a essas transações e vínculos comerciais.</p>
              </faq-accordion>

              <faq-accordion faq-question="A Nova deverá responder sempre as solicitações relativas aos direitos dos titulares?">
                <p>A Nova deverá responder às solicitações dos titulares nas hipóteses em que ela como Controladora dos dados. Para as situações nas quais a Nova atue como Operadora, você deverá contatar o Controlador. Nós iremos responder informando como você deverá proceder e nesse caso, encaminharemos o seu pedido à empresa responsável por respondê-lo.</p>
              </faq-accordion>

              <faq-accordion faq-question="Como a Nova protege os dados pessoais?">
                <p>A Nova encontra-se em um momento de adequação à LGPD e está desenvolvendo medidas de segurança capazes de proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, ou qualquer forma de tratamento inadequado ou ilícito, como previsto na legislação.</p>
                <p class="mt-3">Você pode conferir mais detalhes sobre como a Nova protege seus dados acessando a nossa Política de Privacidade.</p>
              </faq-accordion>

              <faq-accordion faq-question="Como a Nova tem se preparado para estar em conformidade com as normativas de proteção de dados pessoais?">
                <p>Os times internos da Nova estão sendo treinados para adaptação das suas atividades às boas práticas de tratamento de dados pessoais. Nosso compromisso é constante e contínuo para internalizarmos todas as questões inerentes à lei.</p>
                <p class="mt-3">Temos DPO nomeado, o qual pode ser contatado diretamente no e-mail: privacidade.nova@rnova.com.br</p>
                <p class="mt-3">Para evidenciarmos as boas práticas, mantemos uma agenda intensa com diversas ações que buscam trazer a aderência da Nova à cultura da Privacidade e Proteção de dados pessoais e aos novos padrões adotados no Brasil</p>
                <p class="mt-3">A Nova trata a Privacidade e a Proteção de dados como Prioridade! Ressaltamos a nossa preocupação ao instituir internamente comissões de Privacidade e Proteção de Dados e Cibersegurança. As comissões são formadas o por um grupo multidisciplinar e que tem como objetivo, garantir que os novos e já existentes projetos estejam alinhados às necessidades dos nossos clientes, acionistas, parceiros, terceiros e colaboradores.</p>
              </faq-accordion>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import FaqAccordion from "@/components/layout/goods/FaqAccordion.vue";
export default {
  name: "App",
  components: {
    FaqAccordion,
  },
};
</script>

FaqAccordion
