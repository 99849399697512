<template>
  
  <div v-if="newCityScreen()">
    <city-index-view />
  </div>
  <div v-else v-on:mouseleave="mouseleave()">
    <promo-box ref="getModalBox" />
    <header-site :main-menu-color="this.menuColor" :main-menu-font="this.menuFont" :main-header-color="this.headerColor" :main-header-font="this.headerFont" />
    <home-carousel v-if="currentRouteName != 'Buy'" :id-cidade="this.currentCityID" />
    <router-view />
    <whatsapp-button />
    <chatbot-button />
    <phone-button />
    <footer-site />
  </div>
</template>

<style></style>
<script>
import CityIndexView from "@/views/static/CityIndexView.vue";
import ChangeTypeScreen from "@/views/static/ChangeTypeScreen.vue";
import HeaderSite from "@/components/layout/HeaderSite.vue";
import HomeCarousel from "@/components/layout/HomeCarousel.vue";
import FooterSite from "@/components/layout/FooterSite.vue";
import WhatsappButton from "./components/layout/goods/WhatsappButton.vue";

import PhoneButton from "./components/layout/goods/PhoneButton.vue";

import BoxHeaderVoce from "@/components/layout/home/BoxHeaderVoce.vue";
import BoxHeaderEmpresas from "@/components/layout/home/BoxHeaderEmpresas.vue";

import CitySelection from '@/mixins/CitySelection';
import ChatbotButton from "./components/layout/goods/ChatbotButton.vue";

import PromoBox from "@/components/layout/goods/PromoBox.vue";

export default {
  name: "App",
  components: {
    HeaderSite,
    HomeCarousel,
    FooterSite,
    CityIndexView,
    ChangeTypeScreen,
    WhatsappButton,
    PhoneButton,
    ChatbotButton,
    PromoBox,
    BoxHeaderEmpresas,
    BoxHeaderVoce
  },
  computed: {
      currentRouteName() {
          return this.$route.name;
      },
      currentCityID() {
        return this.$cookies.get('selectCity')
        // return 45461;
      }
  },
  methods: {

    showPromoModal() {
      this.$refs.getModalBox.getModal();
    },
    mouseleave() {
      var e = window.event;
      var posY = e.clientY;
      if(!$cookies.get("popupPromo") && posY < 10) {
        this.showPromoModal();
      }
    },
    homeCampaignRedirect() {
      console.log('funciona')
    },
    caralhoVoador() {
      let c = 0;

      if(this.$route.name != 'index' && this.$route.name != 'homeCampaign') {
        if($cookies.get("cookieIndex")) {
          $cookies.remove("cookieIndex");
        }
      }
      if(!$cookies.get("cookieIndex")) {

        if(this.$route.params.planType == 'voce' || this.$route.params.planType == 'empresa') {
          if($cookies.get("selectType")) {
            $cookies.remove("selectType");
          }

          if(this.$route.params.planType == 'voce') {
            c = 1;
          }
          if(this.$route.params.planType == 'empresa') {
            c = 2;
          }

          $cookies.set("cookieIndex", true);
          $cookies.set("selectType", c);
          this.cityValue.saveType(c);
          this.$router.go({name: 'home', params: {planType: ''}});

        }
      }


    },

    returnMenuHeader() {
      if (this.cityValue.showList.dataType == 2) {
        this.menuColor = " bg-gray-400";
        this.menuFont = " text-white";
        this.headerColor = " bg-gray-100";
        this.headerFont = " text-main-purple-500";
      } else {
        this.menuColor = " bg-gray-100";
        this.menuFont = " text-main-purple-500";
        this.headerColor = " bg-main-purple-500";
        this.headerFont = " text-white";
      }
    },
    fazConta() {
      return Math.floor(Math.random() * 1451);
    },
  },

  mixins: [CitySelection],
  updated() {
    this.returnMenuHeader()
    this.caralhoVoador()
  },
  mounted() {
    this.returnMenuHeader()
    this.caralhoVoador()
  },
  created() {
    this.returnMenuHeader()
    this.caralhoVoador()
  },
  beforeCreate() {

      // let routeName = this.$route.name
      // console.log(routeName)

      // if (routeName === "homeCampaign") {
      //   $cookies.set("cookieIndex", true);
      //   $cookies.set("cookieTeste", true);
      //   $cookies.set("selectType", 1);
      //   $cookies.set("selectCity", 1);
      //   // this.cityValue.saveType(1);
      //   if(this.$cookies.get('cookieTeste') === null) {
      //     console.log('teste nulo')
      //     this.$router.go({name: 'home', params: {planType: ''}});
      //   }
      // }


  }
};
</script>
